var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard" },
    [
      _c("v-app", [
        _c(
          "div",
          { staticClass: "inner-container" },
          [
            _c(
              "div",
              {
                staticClass:
                  "head-title light-bg d-flex justify-space-between mb-0 pa-9"
              },
              [
                _c(
                  "h2",
                  { staticClass: "headline font-weight-medium primary--text" },
                  [_vm._v("Settings")]
                )
              ]
            ),
            _c(
              "v-container",
              { staticClass: "px-9", attrs: { fluid: "" } },
              [
                [
                  _c(
                    "v-card",
                    { attrs: { elevation: "0" } },
                    [
                      _c("v-toolbar", {
                        attrs: { flat: "", prominent: "", height: "30" },
                        scopedSlots: _vm._u([
                          {
                            key: "extension",
                            fn: function() {
                              return [
                                _c(
                                  "v-tabs",
                                  {
                                    attrs: {
                                      "background-color": "transparent",
                                      color: "success accent-4"
                                    },
                                    model: {
                                      value: _vm.tabs,
                                      callback: function($$v) {
                                        _vm.tabs = $$v
                                      },
                                      expression: "tabs"
                                    }
                                  },
                                  _vm._l(_vm.items, function(item) {
                                    return _c("v-tab", { key: item }, [
                                      _vm._v(" " + _vm._s(item) + " ")
                                    ])
                                  }),
                                  1
                                )
                              ]
                            },
                            proxy: true
                          }
                        ])
                      }),
                      _c(
                        "v-tabs-items",
                        {
                          model: {
                            value: _vm.tabs,
                            callback: function($$v) {
                              _vm.tabs = $$v
                            },
                            expression: "tabs"
                          }
                        },
                        [
                          _c(
                            "v-tab-item",
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "" } },
                                [
                                  _c(
                                    "v-card-text",
                                    { staticClass: "px-0" },
                                    [
                                      _c(
                                        "v-form",
                                        {
                                          model: {
                                            value: _vm.valid,
                                            callback: function($$v) {
                                              _vm.valid = $$v
                                            },
                                            expression: "valid"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            { staticClass: "mt-0" },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: { cols: "12", sm: "5" }
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        color: "primary",
                                                        depressed: ""
                                                      },
                                                      on: { click: _vm.goToCP }
                                                    },
                                                    [_vm._v("Change Password")]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "profile-col mt-5" },
                                            [
                                              _c("v-divider"),
                                              _c(
                                                "v-row",
                                                { staticClass: "mt-0" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "6"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "h3",
                                                        {
                                                          staticClass:
                                                            "mt-5 secondary--text"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Profile Information"
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                { staticClass: "mt-0" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "5"
                                                      }
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          label: "First Name",
                                                          placeholder:
                                                            "First Name",
                                                          required: "",
                                                          outlined: "",
                                                          dense: "",
                                                          rules: _vm.nameRules
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.updateDetails
                                                              .FirstName,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.updateDetails,
                                                              "FirstName",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "updateDetails.FirstName"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "5"
                                                      }
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          label: "Last Name",
                                                          placeholder:
                                                            "Last Name",
                                                          required: "",
                                                          outlined: "",
                                                          dense: ""
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.updateDetails
                                                              .LastName,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.updateDetails,
                                                              "LastName",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "updateDetails.LastName"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                { staticClass: "mt-0" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "5"
                                                      }
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        directives: [
                                                          {
                                                            name: "mask",
                                                            rawName: "v-mask",
                                                            value:
                                                              "(###) ###-####",
                                                            expression:
                                                              "'(###) ###-####'"
                                                          }
                                                        ],
                                                        attrs: {
                                                          label: "Phone",
                                                          placeholder: "Phone",
                                                          required: "",
                                                          outlined: "",
                                                          dense: "",
                                                          "prepend-inner-icon":
                                                            "mdi-phone",
                                                          rules: _vm.phoneRules
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.updateDetails
                                                              .Phone,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.updateDetails,
                                                              "Phone",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "updateDetails.Phone"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "5"
                                                      }
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          label: "Email",
                                                          placeholder: "Email",
                                                          required: "",
                                                          outlined: "",
                                                          dense: "",
                                                          disabled: ""
                                                        },
                                                        model: {
                                                          value: _vm.email,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.email = $$v
                                                          },
                                                          expression: "email"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                { staticClass: "mt-0" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "5"
                                                      }
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          label: "Company",
                                                          placeholder:
                                                            "Company",
                                                          required: "",
                                                          outlined: "",
                                                          dense: "",
                                                          rules:
                                                            _vm.companyRules
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.updateDetails
                                                              .CompanyName,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.updateDetails,
                                                              "CompanyName",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "updateDetails.CompanyName"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "5"
                                                      }
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          label: "Job Title",
                                                          placeholder:
                                                            "Job Title",
                                                          required: "",
                                                          outlined: "",
                                                          dense: "",
                                                          rules: _vm.jobRules
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.updateDetails
                                                              .JobTitle,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.updateDetails,
                                                              "JobTitle",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "updateDetails.JobTitle"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                { staticClass: "mt-3" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "5"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass: "mr-3",
                                                          attrs: {
                                                            color: "success",
                                                            depressed: "",
                                                            disabled: !_vm.valid
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.updateProfile()
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Update Profile"
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-tab-item",
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "" } },
                                [
                                  _c(
                                    "v-card-title",
                                    { staticClass: "px-0" },
                                    [
                                      _vm._v(" Payment Method "),
                                      _c(
                                        "v-dialog",
                                        {
                                          attrs: { "max-width": "600px" },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                var attrs = ref.attrs
                                                return [
                                                  _c(
                                                    "div",
                                                    { staticClass: "my-2" },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              staticClass:
                                                                "ml-4",
                                                              attrs: {
                                                                color:
                                                                  "success",
                                                                depressed: ""
                                                              }
                                                            },
                                                            "v-btn",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v("mdi-plus")
                                                          ]),
                                                          _vm._v(" Add Card")
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value: _vm.add_card_dialog,
                                            callback: function($$v) {
                                              _vm.add_card_dialog = $$v
                                            },
                                            expression: "add_card_dialog"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-card",
                                            { staticClass: "pa-5" },
                                            [
                                              _c(
                                                "v-card-title",
                                                {
                                                  staticClass:
                                                    "d-flex justify-space-between pa-0 mb-10"
                                                },
                                                [
                                                  _c("h4", [
                                                    _vm._v("Add credit Card")
                                                  ]),
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.add_card_dialog = false
                                                        }
                                                      }
                                                    },
                                                    [_vm._v("mdi-close")]
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-card-text",
                                                { staticClass: "pa-0" },
                                                [
                                                  _c(
                                                    "v-form",
                                                    {
                                                      ref: "add_card_form",
                                                      staticClass: "mb-5",
                                                      attrs: {
                                                        "lazy-validation": ""
                                                      },
                                                      on: {
                                                        submit: function(
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                        }
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.add_card_valid,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.add_card_valid = $$v
                                                        },
                                                        expression:
                                                          "add_card_valid"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-row",
                                                        { staticClass: "mt-0" },
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "py-0",
                                                              attrs: {
                                                                cols: "12",
                                                                sm: "12"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "black--text mb-3 d-flex"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Card Number"
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  attrs: {
                                                                    label:
                                                                      "Card Number",
                                                                    placeholder:
                                                                      "Card Number",
                                                                    required:
                                                                      "",
                                                                    outlined:
                                                                      "",
                                                                    dense: "",
                                                                    rules:
                                                                      _vm.numberRules
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.add_number,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.add_number = $$v
                                                                    },
                                                                    expression:
                                                                      "add_number"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-row",
                                                        { staticClass: "mt-0" },
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "py-0",
                                                              attrs: {
                                                                cols: "12",
                                                                sm: "6"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "black--text mb-3 d-flex"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Expiry Date"
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  attrs: {
                                                                    label:
                                                                      "MM/YYYY",
                                                                    placeholder:
                                                                      "MM/YYYY",
                                                                    required:
                                                                      "",
                                                                    outlined:
                                                                      "",
                                                                    dense: "",
                                                                    rules:
                                                                      _vm.expiryRules
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.add_expiry,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.add_expiry = $$v
                                                                    },
                                                                    expression:
                                                                      "add_expiry"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "py-0",
                                                              attrs: {
                                                                cols: "12",
                                                                sm: "6"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "black--text mb-3 d-flex"
                                                                },
                                                                [_vm._v("CVV")]
                                                              ),
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  attrs: {
                                                                    label:
                                                                      "CVV",
                                                                    placeholder:
                                                                      "CVV",
                                                                    required:
                                                                      "",
                                                                    outlined:
                                                                      "",
                                                                    dense: "",
                                                                    rules:
                                                                      _vm.expiryCVV
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.add_cvv,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.add_cvv = $$v
                                                                    },
                                                                    expression:
                                                                      "add_cvv"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c("v-checkbox", {
                                                        staticClass: "mt-1",
                                                        attrs: {
                                                          label:
                                                            "Make this card as Primary payment source",
                                                          color: "success",
                                                          "hide-details": ""
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.add_primary,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.add_primary = $$v
                                                          },
                                                          expression:
                                                            "add_primary"
                                                        }
                                                      }),
                                                      _c(
                                                        "v-row",
                                                        { staticClass: "mt-3" },
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "py-0",
                                                              attrs: {
                                                                cols: "12",
                                                                sm: "5"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  staticClass:
                                                                    "mr-3",
                                                                  attrs: {
                                                                    color:
                                                                      "success",
                                                                    disabled:
                                                                      _vm.enableAdd ||
                                                                      !_vm.add_card_valid,
                                                                    depressed:
                                                                      ""
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      _vm.addCustCard
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Add Card"
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-card-text",
                                    { staticClass: "px-0" },
                                    [
                                      [
                                        _c("v-data-table", {
                                          staticClass: "elevation-0",
                                          attrs: {
                                            headers: _vm.cardsHeaders,
                                            items: _vm.cardsItems,
                                            "items-per-page": 5
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "item.plus",
                                              fn: function(ref) {
                                                var item = ref.item
                                                return [
                                                  _c("label", [
                                                    _vm._v(
                                                      _vm._s(item.card.number)
                                                    )
                                                  ]),
                                                  item.card.primary
                                                    ? _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            color: "success",
                                                            elevation: "0",
                                                            small: "",
                                                            rounded: ""
                                                          }
                                                        },
                                                        [_vm._v("Primary")]
                                                      )
                                                    : _vm._e()
                                                ]
                                              }
                                            },
                                            {
                                              key: "item.actions",
                                              fn: function(ref) {
                                                var item = ref.item
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "mr-2",
                                                      attrs: { small: "" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.deletecard(
                                                            item
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [_vm._v(" mdi-delete ")]
                                                  )
                                                ]
                                              }
                                            }
                                          ])
                                        })
                                      ],
                                      _c("EditCard", {
                                        attrs: {
                                          editCardData: _vm.editCardData
                                        }
                                      }),
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "mt-6 mb-3 secondary--text",
                                          on: { click: _vm.creds }
                                        },
                                        [_vm._v("Invoice History")]
                                      ),
                                      [
                                        _c("v-data-table", {
                                          staticClass: "elevation-0",
                                          attrs: {
                                            headers: _vm.invoiceHeaders,
                                            items: _vm.invoiceItems,
                                            "items-per-page": 5
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "item.actions",
                                              fn: function(ref) {
                                                var item = ref.item
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "mr-2",
                                                      attrs: { small: "" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.viewDLInvoice(
                                                            item.invoice.id,
                                                            "inline"
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [_vm._v(" mdi-eye ")]
                                                  ),
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "mr-2",
                                                      attrs: { small: "" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.viewDLInvoice(
                                                            item.invoice.id,
                                                            "attachment"
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [_vm._v(" mdi-download ")]
                                                  )
                                                ]
                                              }
                                            }
                                          ])
                                        })
                                      ]
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("LoadingAlert", {
                    attrs: {
                      loading: _vm.loadingAlertFlag,
                      loadingText: _vm.loadingAlertText
                    }
                  }),
                  _c("InfoAlert", {
                    attrs: {
                      InfoAlert: _vm.infoAlertFlag,
                      InfoAlertTitle: _vm.infoAlertTitle,
                      InfoAlertMessage: _vm.infoAlertMessage
                    },
                    on: { emitInfoAlertOkClicked: _vm.clearInfoAlert }
                  }),
                  _c("DecisionAlert", {
                    attrs: {
                      DecisionAlert: _vm.decisionAlertFlag,
                      DecisionAlertTitle: _vm.decisionAlertTitle,
                      DecisionAlertMessage: _vm.decisionAlertMessage,
                      DecisionAlertData: _vm.decisionAlertData
                    },
                    on: {
                      emitDecisionAlertCancelClicked: _vm.clearDecisionModel,
                      emitDecisionAlertOkClicked: function($event) {
                        return _vm.decisionOkAction($event)
                      }
                    }
                  })
                ]
              ],
              2
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }