<template flex>
  <div class="dashboard">
    <v-app>
      <!-- Sizes your content based upon application components -->
      <div class="inner-container">
        <!-- Provides the application the proper gutter -->
        <div class="head-title light-bg d-flex justify-space-between mb-0 pa-9">
          <h2 class="headline font-weight-medium primary--text" >Settings</h2>
        </div>

        <v-container fluid class="px-9">
          <!-- If using vue-router -->
          <template fluid>
            <v-card elevation="0">
              <!-- Tabs Open -->
              <v-toolbar flat prominent height="30">
                <template v-slot:extension>
                  <!-- slider-color="success" -->
                  <v-tabs
                    v-model="tabs"
                    background-color="transparent"
                    color="success accent-4"
                  >
                    <v-tab v-for="item in items" :key="item">
                      {{ item }}
                    </v-tab>
                  </v-tabs>
                </template>
              </v-toolbar>

              <v-tabs-items v-model="tabs">
                <v-tab-item>
                  <v-card flat>
                    <v-card-text class="px-0">
                      <v-form v-model="valid">
                        <v-row class="mt-0">
                          <v-col cols="12" sm="5">
                            <v-btn @click="goToCP" color="primary" depressed
                              >Change Password</v-btn
                            >
                          </v-col>
                        </v-row>
                        <div class="profile-col mt-5">
                          <v-divider></v-divider>
                          <v-row class="mt-0">
                          <v-col cols="12" sm="6">
                          <h3 class="mt-5 secondary--text">Profile Information</h3>
                          </v-col>
                          </v-row>
                          <v-row class="mt-0">
                            <v-col cols="12" sm="5">
                              <!-- <label class="black--text mb-3 d-flex"
                                >First Name</label
                              > -->
                              <v-text-field
                                label="First Name"
                                placeholder="First Name"
                                v-model="updateDetails.FirstName"
                                required
                                outlined
                                dense
                                :rules="nameRules"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="5">
                              <!-- <label class="black--text mb-3 d-flex"
                                >Last Name</label
                              > -->
                              <v-text-field
                                label="Last Name"
                                placeholder="Last Name"
                                v-model="updateDetails.LastName"
                                required
                                outlined
                                dense
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row class="mt-0">
                            <v-col cols="12" sm="5">
                              <!-- <label class="black--text mb-3 d-flex"
                                >Phone</label
                              > -->
                              <v-text-field
                                label="Phone"
                                placeholder="Phone"
                                v-model="updateDetails.Phone"
                                required
                                outlined
                                dense
                                prepend-inner-icon="mdi-phone"
                                :rules="phoneRules"
                                v-mask="'(###) ###-####'"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="5">
                              <v-text-field
                                label="Email"
                                placeholder="Email"
                                v-model="email"
                                required
                                outlined
                                dense
                                disabled
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row class="mt-0">
                            <v-col cols="12" sm="5">
                              <v-text-field
                                label="Company"
                                placeholder="Company"
                                v-model="updateDetails.CompanyName"
                                required
                                outlined
                                dense
                                :rules="companyRules"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="5">
                              <v-text-field
                                label="Job Title"
                                placeholder="Job Title"
                                v-model="updateDetails.JobTitle"
                                required
                                outlined
                                dense
                                :rules="jobRules"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <!-- <v-checkbox
                            v-model="checkbox"
                            label="want to subscribe to the newsletters"
                            color="success"
                            value="want to subscribe to the newsletters"
                            hide-details
                          ></v-checkbox> -->
                          <v-row class="mt-3">
                            <v-col cols="12" sm="5">
                              <v-btn
                                color="success"
                                class="mr-3"
                                depressed
                                :disabled="!valid"
                                @click="updateProfile()"
                                >Update Profile</v-btn
                              >
                            </v-col>
                          </v-row>
                        </div>
                      </v-form>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item>
                  <v-card flat>
                    <v-card-title class="px-0">
                      Payment Method
                      <v-dialog v-model="add_card_dialog" max-width="600px">
                        <template v-slot:activator="{ on, attrs }">
                          <div class="my-2">
                            <v-btn
                              color="success"
                              depressed
                              v-bind="attrs"
                              v-on="on"
                              class="ml-4"
                              ><v-icon>mdi-plus</v-icon> Add Card</v-btn
                            >
                          </div>
                        </template>
                        <v-card class="pa-5">
                          <v-card-title
                            class="d-flex justify-space-between pa-0 mb-10"
                          >
                            <h4>Add credit Card</h4>
                            <v-icon @click="add_card_dialog = false"
                              >mdi-close</v-icon
                            >
                          </v-card-title>
                          <v-card-text class="pa-0">
                            <v-form
                              ref="add_card_form"
                              class="mb-5"
                              v-model="add_card_valid"
                              lazy-validation
                              v-on:submit.prevent
                            >
                              <v-row class="mt-0">
                                <v-col cols="12" sm="12" class="py-0">
                                  <label class="black--text mb-3 d-flex"
                                    >Card Number</label
                                  >
                                  <v-text-field
                                    label="Card Number"
                                    placeholder="Card Number"
                                    v-model="add_number"
                                    required
                                    outlined
                                    dense
                                    :rules="numberRules"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row class="mt-0">
                                <v-col cols="12" sm="6" class="py-0">
                                  <label class="black--text mb-3 d-flex"
                                    >Expiry Date</label
                                  >
                                  <v-text-field
                                    label="MM/YYYY"
                                    placeholder="MM/YYYY"
                                    v-model="add_expiry"
                                    required
                                    outlined
                                    dense
                                    :rules="expiryRules"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" class="py-0">
                                  <label class="black--text mb-3 d-flex"
                                    >CVV</label
                                  >
                                  <v-text-field
                                    label="CVV"
                                    placeholder="CVV"
                                    v-model="add_cvv"
                                    required
                                    outlined
                                    dense
                                    :rules="expiryCVV"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-checkbox
                                v-model="add_primary"
                                label="Make this card as Primary payment source"
                                color="success"
                                hide-details
                                class="mt-1"
                              ></v-checkbox>
                              <v-row class="mt-3">
                                <v-col cols="12" sm="5" class="py-0">
                                  <v-btn
                                    color="success"
                                    class="mr-3"
                                    :disabled="enableAdd || !add_card_valid"
                                    depressed
                                    @click="addCustCard"
                                    >Add Card</v-btn
                                  >
                                </v-col>
                              </v-row>
                            </v-form>
                          </v-card-text>
                        </v-card>
                      </v-dialog>
                    </v-card-title>
                    <v-card-text class="px-0">
                      <template>
                        <v-data-table
                          :headers="cardsHeaders"
                          :items="cardsItems"
                          :items-per-page="5"
                          class="elevation-0"
                        >
                        <template v-slot:item.plus="{ item }">
                            <label>{{ item.card.number }}</label>
                            <v-btn
                              v-if="item.card.primary"
                              color="success"
                              elevation="0"
                              small
                              rounded
                            >Primary</v-btn>
                            <!-- <span v-if="item.card.primary" class="success--text"> primary</span> -->
                        </template>
                          <template v-slot:item.actions="{ item }">
                            <!-- <v-icon small class="mr-2" @click="viewCard(item)">
                              mdi-eye
                            </v-icon> -->
                            <!-- <v-icon small class="mr-2" @click="editcard(item)">
                              mdi-pencil
                            </v-icon> -->
                            <v-icon
                              small
                              class="mr-2"
                              @click="deletecard(item)"
                            >
                              mdi-delete
                            </v-icon>
                          </template>
                        </v-data-table>
                      </template>
                      <EditCard :editCardData="editCardData" />

                      <h4 class="mt-6 mb-3 secondary--text" @click="creds">Invoice History</h4>
                      <template>
                        <v-data-table
                          :headers="invoiceHeaders"
                          :items="invoiceItems"
                          :items-per-page="5"
                          class="elevation-0"
                        >
                          <template v-slot:item.actions="{ item }">
                            <v-icon
                              small
                              class="mr-2"
                              @click="viewDLInvoice(item.invoice.id, 'inline')"
                            >
                              mdi-eye
                            </v-icon>
                            <v-icon
                              small
                              class="mr-2"
                              @click="
                                viewDLInvoice(item.invoice.id, 'attachment')
                              "
                            >
                              mdi-download
                            </v-icon>
                          </template>
                        </v-data-table>
                      </template>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

              </v-tabs-items>
              <!-- Tabs Open -->
            </v-card>
            <LoadingAlert
              :loading="loadingAlertFlag"
              :loadingText="loadingAlertText"
            />
            <InfoAlert
              :InfoAlert="infoAlertFlag"
              :InfoAlertTitle="infoAlertTitle"
              :InfoAlertMessage="infoAlertMessage"
              @emitInfoAlertOkClicked="clearInfoAlert"
            />
            <DecisionAlert
              :DecisionAlert="decisionAlertFlag"
              :DecisionAlertTitle="decisionAlertTitle"
              :DecisionAlertMessage="decisionAlertMessage"
              :DecisionAlertData="decisionAlertData"
              @emitDecisionAlertCancelClicked="clearDecisionModel"
              @emitDecisionAlertOkClicked="decisionOkAction($event)"
            />
          </template>
        </v-container>
      </div>
    </v-app>
  </div>
</template>

<script>
import Vue from 'vue';
import { LSS } from '@core/services';
import LoadingAlert from '../components/LoadingAlert';
import InfoAlert from '../components/InfoAlert';
import DecisionAlert from '../components/DecisionAlert';
import EditCard from '../components/chargebee/EditCard.vue';
import { decodeToken } from '@shared/utils/commom.util'
import {
  ALL_CARDS,
  ADD_CARD,
  UPDATE_CARD,
  DEL_CARD,
  ALL_TRANS,
  ALL_INVOICES,
  DL_INVOICE,
} from '../utils/apptweakqueries';
import {
  UPDATE_PROFILE,
} from 'src/utils/apollo-mutations';
import { MEMBER_DETAILS } from 'src/utils/apollo-queries';
import { API_ORY_URL } from '@config'
import VueMask from 'v-mask'
import { LoginService } from '@services/login.service';

Vue.use(VueMask)
export default Vue.extend({
  name: 'Settings',
  components: {
    LoadingAlert,
    InfoAlert,
    DecisionAlert,
    EditCard,
  },
  data() {
    return {
      credCount : 0,
      //cards
      add_number: '',
      editCardData: {
        hello: 'hello',
      },
      add_cvv: null,
      add_year: '',
      add_month: '',
      add_expiry: '',
      add_primary: false,
      cardlist: [],
      transList: [],
      add_card_dialog: false,
      add_card_valid: false,
      enableAdd: false,
      valid: false,
      memberValid: false,
      numberRules: [
        (v) => !!v || 'Card Number is required',
        (v) =>
          (v && v.length == 16) ||
          'Card Number must be numerical and 16 digits long',
        (v) =>
          /[0-9]{16}/.test(v) ||
          'Card Number must be numerical 16 and digits long',
      ],
      expiryRules: [
        (v) => !!v || 'Expiry Month and Year is required',
        (v) =>
          (v && v.length == 7) || 'Expiry date must be MM/YYYY - ex. 07/2023',
        (v) => (v.substring(0,2) > 0 && v.substring(0,2) < 13) || 'Invalid expiry month',
        (v) =>
          /(0[1-9]|1[0-2])\/([0-9]{4})/.test(v) ||
          'Expiry date must be MM/YYYY - ex. 07/2023',
        (v) => (v.substring(3) > 2020 && v.substring(3) < 2099) || 'Invalid expiry year',
      ],
      expiryCVV: [
        (v) => !!v || 'Card CVV is required',
        (v) =>
          (v && v.length == 3) ||
          'Card CVV must be numerical and 3 digits long',
        (v) =>
          /[0-9]{3}/.test(v) || 'Card CVV must be numerical and 3 digits long',
      ],
      nameRules: [
        (v) => !!v || 'Name is required',
      ],
      phoneRules: [
        (v) => !!v || 'Phone is required',
      ],
      companyRules: [
        (v) => !!v || 'Company Name is required',
      ],
      jobRules: [
        (v) => !!v || 'Job Title is required',
      ],
      emailRules: [
        v => !!v || 'Email is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
      add_loading: false,
      addMsgModel: false,
      addMessage: '',
      addMsgTitle: '',

      loadingAlertFlag: false,
      loadingAlertText: 'Validating...',

      infoAlertFlag: false,
      infoAlertTitle: 'Info',
      infoAlertMessage: '',

      decisionAlertFlag: false,
      decisionAlertTitle: 'Decision',
      decisionAlertMessage: '',
      decisionAlertData: {},

      invoiceHeaders: [
        {
          text: 'Invoice_Id',
          align: 'start',
          sortable: false,
          value: 'invoice.id',
        },
        { text: 'Due_Date', value: 'invoice.due_date' },
        { text: 'Paid_At', value: 'invoice.paid_at' },
        { text: 'Recurring', value: 'invoice.recurring' },
        { text: 'Total', value: 'invoice.total' },
        { text: 'Status', value: 'invoice.status' },
        { text: 'Action', value: 'actions' },
      ],
      invoiceItems: [],

      transactionHeaders: [
        {
          text: 'Invoice_Id',
          align: 'start',
          sortable: false,
          value: 'invoice.id',
        },
        { text: 'Due_Date', value: 'invoice.due_date' },
        { text: 'Paid_At', value: 'invoice.paid_at' },
        { text: 'Recurring', value: 'invoice.recurring' },
        { text: 'Total', value: 'invoice.total' },
        { text: 'Status', value: 'invoice.status' },
        { text: 'Action', value: 'actions' },
      ],
      transactionItems: [],

      cardsHeaders: [
        {
          text: 'Card_Id',
          align: 'start',
          sortable: false,
          value: 'card.id',
        },
        { text: 'Type', value: 'card.type' },
        // { text: 'Number', value: 'card.number' },
        { text: 'Number', value: 'plus' },
        { text: 'Expiry', value: 'card.expiry' },
        { text: 'Action', value: 'actions' },
      ],
      cardsItems: [ //TODO
        // {
        //   card: {
        //     id: 1,
        //     type: 'Visa',
        //     number: '9876543210123456',
        //     expiry: '12/2023',
        //     primary: true
        //   },
        // },
        // {
        //   card: {
        //     id: 2,
        //     type: 'Master',
        //     number: '1234567890123456',
        //     expiry: '09/2025',
        //     primary: false
        //   },
        // },
      ],

      email: '',
      password: '',
      checkbox: false,
      inviteDialog: false,
      select: '',
      emailForInvite: '',

      tabs: null,
      items: ['Account Settings', 'Payment'],
      text: 'text',

      updateDetails: {
        FirstName: '',
        LastName: '',
        Phone: '',
        CompanyName: '',
        JobTitle: '',
      },
      addMemberDialog: false,

      memberDetails: {
        FirstName: '',
        LastName: '',
        Phone: '',
        Email: '',
        CompanyName: '',
        JobTitle: '',
      },

      carddetails: [],

      transactionhistory: [],

      isCheckboxTrue: false,

      onClickShow: false,

      userDetails: {},
    };
  },

  methods: {
    creds(){
      this.credCount++
      if(this.credCount == 20){
        console.log('hello')
        this.credCount = 0
      }
    },
    addCustCard() {
      this.$refs.add_card_form.validate();
      if (!this.add_number || !this.add_expiry || !this.add_cvv) {
        //console.log('empty');
        return;
      }
      if (!this.add_card_valid) {
        return;
      }
      // const newcard = {
      //   card: {
      //     id: 3,
      //     type: 'Master',
      //     number: this.add_number,
      //     expiry: this.add_expiry,
      //     primary: this.add_primary,
      //   },
      // };
      this.loadingAlertFlag = true;
      this.loadingAlertText = 'Validating...';
      let splitExpiry = this.add_expiry.split('/');
      this.$apollo
        .query({
          query: ADD_CARD,
          variables: {
            custId: decodeToken(LSS.token).UserId,
            number: this.add_number,
            cvv: parseInt(this.add_cvv),
            year: parseInt(splitExpiry[1]),
            month: parseInt(splitExpiry[0]),
            primary: this.add_primary,
          },
        })
        .then((res) => {
          //console.log(res.data);
          if(!res.data.addCard){
            this.loadingAlertFlag = false;
            this.add_card_dialog = false;
            this.infoAlertFlag = true;
            this.infoAlertTitle = 'Error!!!';
            this.infoAlertMessage = 'Validation failed...';
            return;
          }

          this.loadingAlertFlag = false;
          this.add_card_dialog = false;
          this.infoAlertFlag = true;
          this.infoAlertTitle = 'Success!!!';
          this.infoAlertMessage = 'Card Added successfully';
          //this.cardsItems.push(newcard);
          this.add_number = '';
          this.add_expiry = '';
          this.add_cvv = '';
          this.getCards();
        })
        .catch((error) => {
          //console.log(error);
          this.loadingAlertFlag = false;
          this.add_card_dialog = false;
          this.infoAlertFlag = true;
          this.infoAlertTitle = 'Error!!!';
          this.infoAlertMessage = 'Something went wrong...';
        });
    },
    clearInfoAlert() {
      //console.log('clearInfoAlert');
      this.infoAlertFlag = false;
    },
    updateCustCard() {
      //console.log('updateCustCard');
      this.$apollo
        .query({
          query: UPDATE_CARD,
          variables: {
            cardId: '123342',
            first_name: this.update_first_name,
            last_name: this.update_last_name,
            billing_city: this.update_billing_city,
          },
        })
        .then((res) => {
          //console.log(res.data);
        })
        .catch((error) => {
          //console.log(error);
        });
    },
    deleteCustCard() {
      //console.log('deleteCustCard');
    },
    getCards() {
      //console.log('getCards');
      this.$apollo
        .query({
          query: ALL_CARDS,
          variables: {
            custId: decodeToken(LSS.token).UserId,
            limit: 3,
            type: 'card',
          },
        })
        .then((res) => {
          //console.log(res.data);
          if(!res.data.getAllCards){
            return;
          }
          this.cardsItems = res.data.getAllCards.list;
        })
        .catch((error) => {
          //console.log(error);
        });
    },
    getTrans() {
      //console.log('getTrans');
      this.$apollo
        .query({
          query: ALL_TRANS,
          variables: {
            custId: decodeToken(LSS.token).UserId,
            transId: 'all',
            limit: 3,
            offset: '["1600622718000","266000001127"]',
          },
        })
        .then((res) => {
          //console.log(res.data);
        })
        .catch((error) => {
          //console.log(error);
        });
    },
    getInvoices() {
      //console.log('getInvoices');
      const custId = decodeToken(LSS.token).UserId
      this.$apollo
        .query({
          query: ALL_INVOICES,
          variables: {
            custId: custId,
            limit: 10,
          },
        })
        .then((res) => {
          //console.log(res.data);
          this.invoiceItems = res.data.getAllInvoices.list
            ? res.data.getAllInvoices.list
            : [];
        })
        .catch((error) => {
          //console.log(error);
        });
    },
    goToCP() {
      window.location.href = API_ORY_URL + 'self-service/settings/browser';
    },
    viewDLInvoice(id, type) {
      //console.log(id);
      this.loadingAlertFlag = true;
      this.loadingAlertText = 'Preparing...';
      //console.log('downloadInvoice');
      this.$apollo
        .query({
          query: DL_INVOICE,
          variables: {
            invoice_id: id,
            disposition_type: type,
          },
        })
        .then((res) => {
          //console.log(res.data);
          this.loadingAlertFlag = false;
          const url = res.data.dlInvoice.download
            ? res.data.dlInvoice.download.download_url
            : '';
          window.open(url);
        })
        .catch((error) => {
          //console.log(error);
        });
    },
    updateProfile() {
      //console.log('updateProfile');
      this.loadingAlertFlag = true;
      this.loadingAlertText = 'Updating...';
      this.$apollo
        .mutate({
          mutation: UPDATE_PROFILE,
          variables: {
            securityPrincipleID: decodeToken(LSS.token).UserId,

            updateProfileReq: {
              FirstName: this.updateDetails.FirstName,
              LastName: this.updateDetails.LastName,
              Phone: this.updateDetails.Phone,
              CompanyName: this.updateDetails.CompanyName,
              JobTitle: this.updateDetails.JobTitle,
            },
          },
        })
        .then((res) => {
          //console.log(res.data);
          this.loadingAlertFlag = false;
          if (res.data.updateProfile.code == 0) {
            this.infoAlertFlag = true;
            this.infoAlertTitle = 'Success!!!';
            this.infoAlertMessage = 'User Details Updated successfully';
          } else {
            this.infoAlertFlag = true;
            this.infoAlertTitle = 'Failed!!!';
            this.infoAlertMessage = 'Please try later';
          }
          if (res.data.updateProfile) {
            //console.log(res.data.updateProfile)
            const userDetails = {
              CompanyName: res.data.updateProfile.content.CompanyName,
              FirstName: res.data.updateProfile.content.FirstName,
              JobTitle: res.data.updateProfile.content.JobTitle,
              LastName: res.data.updateProfile.content.LastName,
              Phone: res.data.updateProfile.content.Phone,
            };
            this.updateData(userDetails);
            this.$root.$emit('userDetailsUpdated', userDetails);
            this.getUserDetails(decodeToken(LSS.token).UserId);
          }
        })
        .catch((error) => {
          //console.log(error);
        });
    },
    async getUserDetails(UserID) {
      const token = await LoginService.getUserDetails({
        UserID: UserID,
        SessId: null
      });
      const user = decodeToken(token);
      //console.log(user);
      LSS.token = token
      this.$root.$emit('userNameFetched', user);
      return user;
    },
    editcard(data) {
      //console.log(data);
      const card = {
        id: data.card.id,
        expiry: data.card.expiry,
        number: data.card.number,
        cvv: data.card.type,
        now: Date.now(),
      };
      this.editCardData = card;
    },
    deletecard(data) {
      //console.log(data);
      this.decisionAlertFlag = true;
      this.decisionAlertTitle = 'Warning!!!';
      this.decisionAlertMessage = 'Are you sure ?';
      this.decisionAlertData = {
        id: data.card.id,
        from: 'deleteCard',
      };
    },
    clearDecisionModel() {
      this.decisionAlertFlag = false;
    },
    decisionOkAction(event) {
      //console.log(event);
      this.decisionAlertFlag = false;
      this.loadingAlertFlag = true;
      this.loadingAlertText = 'Deleting...';
      const id = event.id;
      const idString = id.toString();
      this.$apollo
        .query({
          query: DEL_CARD,
          variables: {
            cardId: idString,
          },
        })
        .then((res) => {
          //console.log(res.data);
          this.loadingAlertFlag = false;
          this.infoAlertFlag = true;
          this.infoAlertTitle = 'Success!!!';
          this.infoAlertMessage = 'Card Deleted successfully';
          //console.log(this.cardsItems);
          this.cardsItems = this.cardsItems.filter((obj) => obj.card.id !== id);
          //console.log(this.cardsItems);
        })
        .catch((error) => {
          //console.log(error);
        });
    },
    updateData(data) {
      this.updateDetails = data;
      this.email = decodeToken(LSS.token).Email
    },
  },
  mounted() {
    this.getCards();
    this.getTrans();
    this.getInvoices();
    this.add_card_valid = false;
    this.enableAdd = false;
    this.updateData(decodeToken(LSS.token));
  },
});
</script>
